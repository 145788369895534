<template>
	<div class="container">
		<div class="form-holder">
			<b-form @submit="submitForm">
				<div class="form-item-hold">
					<b-form-group id="input-group-1" :state="nameState" :invalid-feedback="nameInvalidFeedback">
						<b-form-input v-model="formdata.name" placeholder="Your Name" :state="nameState"></b-form-input>
					</b-form-group>
				</div>

				<div class="form-item-hold">
					<b-form-group id="input-group-2" :state="emailState" :invalid-feedback="emailInvalidFeedback">
						<b-form-input v-model="formdata.email" placeholder="Email Address" :state="emailState"></b-form-input>
					</b-form-group>
				</div>

				<div class="form-item-hold">
					<b-form-group id="input-group-3" :state="phoneState" :invalid-feedback="phoneInvalidFeedback">
						<b-form-input v-model="formdata.phone" placeholder="Your Phone Number" :state="phoneState"></b-form-input>
					</b-form-group>
				</div>

				<div class="form-item-hold">
					<b-form-group id="input-group-4" label-for="input-4" :state="msgState" :invalid-feedback="msgInvalidFeedback">
						<b-form-textarea
								id="msg"
								v-model="formdata.msg"
								:placeholder="msgplaceholder"
								rows="6"
								max-rows="6"
						></b-form-textarea>
					</b-form-group>
				</div>

				<input type="hidden" id="robots" ref="robots" v-model="robots" />
			</b-form>

			<div v-if="submissionComplete === false" class="primary-button bgdark textwhite" v-on:click="submitForm" v-bind:class="{'button-is-working':(formworking === true)}">
				<span>Submit</span>
				<WorkingButtonInsert v-if="formworking === true"></WorkingButtonInsert>
			</div>

		</div>
	</div>
</template>

<script>
import axios from "axios";
import WorkingButtonInsert from "@/components/WorkingButtonInsert";
export default {
	name: "ContactForm",
	components: {
		WorkingButtonInsert
	},
	props: {
		page: Object,
	},
	data: function () {
		return {
			checksTriggered: false,
			formworking: false,
			submissionComplete: false,
			formdata: {
				name: '',
				email: '',
				phone: '',
				msg: '',
			},
			robots: '',
		}
	},
	computed: {
		formName() {
			return this.page.name;
		},
		msgplaceholder() {
			if(this.formName === 'report-news') return 'News story details';
			return 'Please enter your message';
		},
		nameState() {
			if(this.checksTriggered === false && this.formdata.name.length < 2) {
				return null;
			}
			else if(this.formdata.name.length >= 2) {
				return true;
			}
			return false;
		},
		nameInvalidFeedback() {
			if (this.formdata.name.length > 0) {
				return 'Enter at least 2 characters.'
			}
			return 'Please enter something.'
		},
		emailState() {
			if(this.checksTriggered === false && this.formdata.email.length >= 0 && /.+@.+\..+/.test(this.formdata.email) === false) {
				return null;
			}
			else if(this.formdata.email.length >= 0 && /.+@.+\..+/.test(this.formdata.email)) {
				return true;
			}
			return false;
		},
		emailInvalidFeedback() {
			return 'Please enter a valid email address.'
		},
		phoneState() {
			if(this.checksTriggered === false && this.formdata.phone.length < 6) {
				return null;
			}
			else if(this.formdata.phone.length >= 6) {
				return true;
			}
			return false;
		},
		phoneInvalidFeedback() {
			return 'Please enter a valid phone number.'
		},
		msgState() {
			if(this.checksTriggered === false && this.formdata.msg.length === 0) {
				return null;
			}
			else if(this.formdata.msg.length > 1000 || this.formdata.msg.length === 0) {
				return false;
			}
			return true;
		},
		msgInvalidFeedback() {
			return 'Please enter a message. Max 1000 characters.';
		},
	},
	methods: {
		submitForm() {
			if(this.formworking === false) { //only proceed if not already waiting on callback
				this.checksTriggered = true; //check for validations
				var checkitems = [this.nameState, this.emailState, this.phoneState, this.msgState];
				for(var i=0; i<checkitems.length; i++) {
					if(checkitems[i] === false) return false;
				}
				if(this.robots.length > 0) return false;
				this.formworking = true; //make button working class
				var form = {};
				form.formdata = this.formdata;
				form.domain = this.$store.state.domain;
				form.formname = this.formName;
				var self = this;
				axios.post(
					"/post/submit-contact-form.php",
					JSON.stringify({data: form})
				).then(function(response) {
					var responsejson = response.data; //response.data is returned info
					self.formworking = false;
					if(responsejson.error === 1) {
						self.$store.commit("showKalert", responsejson);
					}
					else if(responsejson.success === 1) {
						self.$store.commit("showKalert", responsejson);
						self.submissionComplete = true;
					}
				}).catch(function(error) {
					console.log(error);
				});
			}
		},
	},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.container {
	padding-bottom: 40px;
}
</style>