<template>
	<div class="searchbox">
		<b-input-group class="">
			<b-form-input v-model="searchtext" @keypress.enter="dosearch"></b-form-input>
			<b-input-group-append>
				<b-button @click="dosearch">
					<b-icon icon='search' class="text-white"></b-icon>
				</b-button>
			</b-input-group-append>
		</b-input-group>
	</div>
</template>

<script>
export default {
	name: "Searchbox",
	components: {},
	props: {},
	data: function () {
		return {
			searchtext: '',
		}
	},
	computed: {},
	methods: {
		dosearch() {
			if(this.$router.currentRoute.path === '/search-results/') { //start the ajax search
				this.$store.commit('internalSearchUpdate', this.searchtext);
			}
			else {
				this.$router.push('/search-results/?q='+this.searchtext);
			}
		},
	},
	watch: {},
	mounted() {

	}
}
</script>
<style>
.searchbox .form-control {
	border-radius: 30px;
	border-color: transparent;
}

.searchbox .input-group .btn {
	background: #fff;
	border: #fff;
	border-radius: 0 30px 30px 0;
	top: 1px;
}
.searchbox .input-group .btn .b-icon.bi {
	color: #000 !important;
}
</style>
<style scoped>

</style>