<template>
	<div class="tile-article">
		<div v-if="$store.state.loading === false && tagText" class="tag" :class="tagClass">
			{{tagText}}
		</div>
		<a v-if="$store.state.loading === false" :href="newsitem.url" class="tile-linker" :data-targpagetpl="newsitem.pagetemplate">
			<div v-if="newsitem.hero_image && newsitem.hero_image.length > 0" class="image-parent" :style="bgimgoverride">
				<div class="image-container">
					<img class="hero-image" :src="newsitem.hero_image" :alt="newsitem.heading+' image'" />
				</div>
			</div>
			<div class="news-card-bottom">
				<p class="tile-heading">{{newsitem.heading}}</p>
				<p class="tile-sub">{{newsitem.subheading}}</p>
			</div>
		</a>
		<div v-if="$store.state.loading === true" class="tile-linker">
			<div class="image-parent">
				<div class="image-container">
					<b-skeleton-img no-aspect height="100%" width="100%"></b-skeleton-img>
				</div>
			</div>
			<div class="news-card-bottom">
				<b-skeleton animation="wave" class="mb-3" height="26px" width="85%"></b-skeleton>
				<b-skeleton animation="wave" height="16px" width="60%"></b-skeleton>
				<b-skeleton animation="wave" height="16px" width="60%"></b-skeleton>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "TileArticle",
	components: {},
	props: {
		newsitem: Object
	},
	data: function () {
		return {}
	},
	computed: {
		tagText() {
			if(this.newsitem && this.newsitem.article_type) {
				if(this.$store.state.metroDomains.indexOf(this.$store.state.domain) > -1 && this.newsitem && this.newsitem.article_type === 'News') {
					return 'Info'; //for metro stations show 'info' instead of 'news'
				}
				else {
					return this.newsitem.article_type;
				}
			}
			return null;
		},
		tagClass() {
			if(this.tagText === 'Win') return 'yellowtag';
			return '';
		},
		bgimgoverride() {
			if(this.newsitem.background_override_image && this.newsitem.background_override_image.length > 0) {
				return "background-image: url('"+this.newsitem.background_override_image+"') !important;";
			}
			return "";
		},
	},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.tile-article {
	background: #FFFFFF;
	flex-basis: 23.5%;
	margin-left: 1%;
	margin-right: 1%;
	box-sizing: border-box;
	height: 440px;
	position: relative;
	margin-bottom: 40px;
}
.tile-article:nth-child(4n-3) { /* item 1, 5, 9 etc */
	margin-left: 0;
}
.tile-article:nth-child(4n) { /* item 4, 8, 12 etc */
	margin-right: 0;
}
.tile-linker {
	height: 100%;
	display: block;
	transition: all 0.3s ease-in-out;
	background-color: #FFF;
}
.tile-linker:hover {
	box-shadow: 0 0 15px 10px rgba(0,0,0,0.1);
}
.image-parent {
	width: 100%;
	height: 60%;
}
.am .image-parent {
	background-image: url('/public-static/backgrounds/home-hero-bg-mob-am.jpg');
}
.fm .image-parent {
	background-image: url('/public-static/backgrounds/home-hero-bg-mob-fm.jpg');
}
.mp .image-parent {
	background-image: url('/public-static/backgrounds/home-hero-bg-mob-mp.jpg');
}
.magic .image-parent {
	background-image: url('/public-static/backgrounds/home-hero-bg-mob-magic.jpg');
}
.image-container {
	max-height: 100%;
	max-width: 100%;
	height: 100%;
	width: 100%;
	position: relative;
	display: flex;
	justify-content: center;
	overflow: hidden;
}
.hero-image {
	height: 100%;
	width: 100%;
	object-fit: cover;
	transition: all 0.3s ease-in-out;
}
.tile-linker:hover .hero-image {
	transform: scale(1.05);
}
.news-card-bottom {
	padding: 35px;
	overflow: hidden;
	height: 40%;
}
.tile-heading {
	font-size: 20px;
	font-family: 'Montserrat';
	font-weight: bold;
	margin-bottom: 10px;
}
.tile-sub {
	font-size: 16px;
	font-family: 'Montserrat';
	line-height: 1.4;
}
.tag {
	position: absolute;
	background: #FFF;
	text-align: center;
	z-index: 1;
	font-family: 'Montserrat';
	font-size: 15px;
	text-transform: uppercase;
	font-weight: 600;
	top: -14px;
	left: 14px;
	padding: 5px 20px;
	border-radius: 20px;
	letter-spacing: 0.03em;
}
.yellowtag {
	background-color: #FFDD00;
}

@media screen and (max-width: 1600px) {
	.news-card-bottom {
		padding: 30px;
	}
	.tile-sub {
		font-size: 14px;
	}
}
@media screen and (max-width: 1050px) {
	.tile-article {
		width: 48%;
		flex-basis: 48%;
		height: 380px;
		margin-left: unset !important;
		margin-right: unset !important;
	}
}
@media screen and (max-width: 800px) {
	.tile-heading {
		font-size: 16px;
	}
	.tile-sub {
		font-size: 14px;
	}
}
@media screen and (max-width: 640px) {
	.news-card-bottom {
		padding: 30px;
	}
}
@media screen and (max-width: 570px) {
	.tile-article {
		max-width: unset;
		width: 100%;
		flex-basis: 100%;
		height: 380px;
	}
}
</style>