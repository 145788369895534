<template>
	<div class="footer-section-holder">

		<AdSection adtype="footer"></AdSection>

		<div class="footer-main">
			<div class="container">
				<div class="footer-left">
					<img :alt="$store.state.globals.domainInfo.meta_title+' logo'" class="logo-image" :src="footerlogourl" />
				</div>
				<div class="footer-right">
					<div class="footer-right-col first-right-col">
						<div v-if="$store.state.metroDomains.indexOf($store.state.domain) === -1" class="menuitem">
							<a href="/news" data-targpagetpl="aggregate-news">News</a>
						</div>
						<div class="menuitem">
							<a href="/shows" data-targpagetpl="aggregate-shows">Shows</a>
						</div>
						<div class="menuitem">
							<a href="/win" data-targpagetpl="aggregate-comps">Win</a>
						</div>
						<div class="menuitem">
							<a v-if="$store.state.domain === '3mp'" href="/community" data-targpagetpl="aggregate-events">
								<span>Community</span>
							</a>
							<a v-else href="/events" data-targpagetpl="aggregate-events">
								<span>Events</span>
							</a>
						</div>
					</div>
					<div class="footer-right-col">
						<div class="menuitem">
<!--							<a href="/site/Privacy_Policy.pdf" target="_blank">Privacy Policy</a>-->
							<a href="/privacy-policy" data-targpagetpl="simple-page">Privacy Policy</a>
						</div>
						<div class="menuitem">
							<a href="/general-terms-conditions" data-targpagetpl="simple-page">Competition T&Cs</a>
						</div>
						<div class="menuitem">
							<a href="/complaints">Complaints</a>
						</div>
						<div class="menuitem">
							<a href="/codes-of-conduct" data-targpagetpl="simple-page">Codes Of Conduct</a>
						</div>
					</div>
					<div class="footer-right-col">
						<div class="menuitem">
							<a href="/forms/contact-us" data-targpagetpl="public-form">Contact</a>
						</div>
						<div class="menuitem">
							<a href="/forms/advertising-enquiry" data-targpagetpl="public-form">Advertise</a>
						</div>
						<div class="menuitem">
							<a v-if="$store.state.domain !== '3mp'" href="/ace-jobs" data-targpagetpl="simple-page">Jobs</a>
						</div>
						<div class="social-links">
							<a v-if="$store.state.globals.domainInfo.facebook_link.length > 0" :href="$store.state.globals.domainInfo.facebook_link" target="_blank">
								<i class="mdi mdi-facebook" style="font-size: 20px;"></i>
							</a>
							<a v-if="$store.state.globals.domainInfo.instagram_link.length > 0" :href="$store.state.globals.domainInfo.instagram_link" target="_blank">
								<i class="mdi mdi-instagram" style="font-size: 20px;"></i>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="footer-below">
			<div class="container">
				<div class="subfooter-left">
					<span>Copyright &copy;{{fullyear}}.  All rights reserved.  ACE Radio Broadcasters. </span>
					<span v-if="$store.getters.isMetroDomain === false">Committed to Regional Victoria and Southern NSW.</span>
				</div>
				<div class="subfooter-right">
					<a href="https://ace.digital/" target="_blank" style="display: flex; align-content: center;">
						<p class="builtby-span" style="padding-top: 4px; line-height: 1; color: #000; font-size: 12px; display: inline-block; margin-right: 5px;">Built by </p>
						<img style="height: 20px;" src="/public-static/logos/ace_digital_logo.png" class="ace-digital-logo" alt="ACE Digital logo" />
					</a>
				</div>
			</div>
		</div>

		<FooterMobileNav></FooterMobileNav>

	</div>
</template>

<script>
import FooterMobileNav from "@/components/FooterMobileNav";
import AdSection from "@/components/AdSection";
export default {
	name: "FooterSection",
	components: {AdSection, FooterMobileNav},
	props: {},
	data: function () {
		return {
			fullyear: 2021,
		}
	},
	computed: {
		footerlogourl() {
			let filename = this.$store.state.domain+'.svg?v=4';
			if(this.$store.state.metroDomains.indexOf(this.$store.state.domain) > -1) {
				filename = this.$store.state.domain+'-footer.svg?v=4';
			}
			return this.$store.state.urlroot+'/public-static/logos/'+filename;
		},
	},
	methods: {},
	watch: {},
	mounted() {
		var d = new Date();
		this.fullyear = d.getFullYear();
	}
}
</script>

<style scoped>
.footer-main {
	color: #FFF;
	background-color: #111111;
	padding-top: 80px;
	padding-bottom: 80px;
	width: 100%;
	font-family: 'Montserrat';
}
.mp .footer-main {
	background-color: #25BEC8;
}
.magic .footer-main {
	background-color: #212121;
}
.am .footer-main {
	background-image: url('/public-static/backgrounds/am-footer.png');
	background-size: auto 100%;
	background-position: top left;
	background-repeat: no-repeat;
}
.fm .footer-main {
	background-image: url('/public-static/backgrounds/fm-footer.png');
	background-size: auto 100%;
	background-position: top left;
	background-repeat: no-repeat;
}
.footer-main .container {
	display: flex;
	width: 100%;
	justify-content: space-between;
}
.footer-left {
	width: 49%;
}
.footer-right {
	width: 49%;
	display: flex;
	justify-content: space-between;
}
.logo-image {
	height: 90px;
	width: 200px;
	object-fit: contain;
}
.footer-right-col {
	width: 32%;
}
.menuitem {
	font-size: 14px;
	text-transform: uppercase;
	padding-bottom: 10px;
	transition: all 0.3s ease-in-out;
	font-weight: 500;
	letter-spacing: 0.05em;
}
.menuitem:hover {
	color: #E0E0E0;
}
.footer-below {
	padding-top: 20px;
	padding-bottom: 20px;
	font-family: 'Roboto';
	font-size: 13px;
}
.footer-below .container {
	display: flex;
	justify-content: space-between;
}

@media screen and (max-width: 1400px) {
	.footer-left {
		width: 30%;
		min-width: 400px;
	}
	.footer-right {
		width: 69%;
	}
}
@media screen and (max-width: 1150px) {
	.first-right-col {
		display: none;
	}
	.footer-right-col {
		width: 47%;
	}
	.footer-below .container {
		flex-wrap: wrap;
	}
	.subfooter-left, .subfooter-right {
		width: unset;
	}
	.subfooter-left {
		margin-bottom: 10px;
		margin-right: 10px;
	}
}
@media screen and (max-width: 950px) {
	.footer-left {
		min-width: 280px;
	}
}
@media screen and (max-width: 768px) {
	.footer-below {
		padding-bottom: 100px; /* to handle mobile nav at bottom */
	}
	.footer-right {
		flex-wrap: wrap;
		width: 50%;
		max-width: 240px;
	}
	.first-right-col, .footer-right-col {
		display: block;
		width: 100%;
	}
}
@media screen and (max-width: 600px) {
	.footer-main .container {
		flex-wrap: wrap;
	}
	.footer-left {
		width: 100%;
		min-width: unset;
	}
	.footer-right {
		width: 100%;
		max-width: unset;
		margin-top: 40px;
	}
	.footer-left, .footer-right, .footer-below {
		text-align: center;
	}
	.footer-below .container {
		display: block;
	}
	.subfooter-right a {
		justify-content: center;
		margin-top: 20px;
	}
}
</style>