<template>
	<div class="podcast-tile-section">
		<div class="container">
			<div class="top-items">
				<p class="minor-heading">Latest Podcasts</p>
				<router-link v-if="tileshows.length >= 4" to="/podcasts/">
					<span class="view-more-textlink text-accent">More Podcasts</span>
				</router-link>
			</div>
			<div v-if="$store.state.loading === true" class="tile-holder">
				<TilePodcast v-for="i in [0,1,2,3]" :key="i"></TilePodcast>
			</div>
			<div v-if="$store.state.loading === false" class="tile-holder">
				<TilePodcast v-for="(st, index) in tileshows" :showitem="st" :key="index"></TilePodcast>
			</div>
		</div>
	</div>
</template>

<script>
import TilePodcast from "@/components/TilePodcast";
export default {
	name: "PodcastTileSection",
	components: {
		TilePodcast
	},
	props: {
		tileshows: Array,
	},
	data: function () {
		return {}
	},
	computed: {},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.podcast-tile-section {
	padding-top: 60px;
	padding-bottom: 60px;
	background-color: #FFFFFF;
	display: flex;
}
.top-items {
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
}
.tile-holder {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	row-gap: 40px;
}
.minor-heading {
	padding-right: 20px;
}
</style>