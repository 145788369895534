import Vue from 'vue'
import VueRouter from 'vue-router'
import Vuex from 'vuex'
import App from './App.vue'
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import RouterManager from "@/components/RouterManager";

import '@/style.css'

Vue.use(VueRouter)
Vue.use(Vuex)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.config.productionTip = false

//routing - PW handles everything by returning the template required, htaccess
const routes = [
	{path: '/*', component: RouterManager},
]

const router = new VueRouter({
	history: true,
	hashbang: false,
	routes: routes,
	mode: 'history',
});

let isDevServer = false;
if(window.location.origin.indexOf('localhost') > -1) {
	isDevServer = true;
}

const store = new Vuex.Store({
	state: {
		kalert: {
			shown: false,
			text: "",
			type: 'danger',
		},
		urlroot: window.urlroot,
		vuepage: window.vuepage,
		globals: window.vuepage.globals,
		pagetemplate: window.vuepage.pagetemplate,
		pagename: window.vuepage.name,
		pagetitle: window.vuepage.pageTitle,
		pagepath: '',
		loading: false,
		gtagua: window.gtagua,
		domain: window.domain,
		isDevServer: isDevServer,

		fireplaypause: 0,
		adZones: [],
		adZoneContent: [],

		amDomains: ['3wm','3ha','3yb','g1242','3cs','3sh','2ay','2qn','3ne'],
		fmDomains: ['mixx1013','mixx889','coast','trfm','mixx1063','mixx1077','edqn','ewng'],
		mpDomains: ['3mp','2ue','4bh'],
		magicDomains: ['magic1278'],
		metroDomains: ['3mp','2ue','4bh','magic1278'],
		searchVal: '',
	},
	getters: {
		skin(state) {
			if(state.mpDomains.includes(state.domain)) return 'mp';
			else if(state.magicDomains.includes(state.domain)) return 'magic';
			else if(state.amDomains.includes(state.domain)) return 'am';
			else return 'fm';
		},
		isMetroDomain(state) {
			if(state.metroDomains.includes(state.domain)) return true;
			return false;
		}
	},
	mutations: {
		internalSearchUpdate(state, val) {
			state.searchVal = val;
		},
		clearAdZones(state) {
			state.adZones = [];
			state.adContent = [];
		},
		registerAdZone(state, zoneId) {
			let adZoneIndex = state.adZones.length; //index of the item we're about to add
			state.adZones.push(zoneId);
			return adZoneIndex;
		},
		setAdContent(state, arr) {
			let newarr = [];
			for(let myitemkey in arr) {
				newarr.push(arr[myitemkey].html);
			}
			state.adZoneContent = newarr;
		},
		pagepathmutation(state, routepath) {
			let adjusted = routepath.replace(state.urlroot, '');
			if (adjusted.slice(adjusted.length - 1) === "/") {
				adjusted = adjusted.slice(0, -1);
			}
			state.pagepath = adjusted;
		},
		setloading(state, newbool) {
			state.loading = newbool;
		},
		setPageTitle(state, newtitle) {
			state.pagetitle = newtitle;
		},
		setPageTemplate(state, newtemplate) {
			state.pagetemplate = newtemplate;
		},
		setPageName(state, newname) {
			state.pagename = newname;
		},
		setVuepage(state, vp) {
			state.vuepage = vp;
			if (vp.globals) {
				state.globals = vp.globals;
			}
		},
		switchDomain(state, newdom) {
			state.domain = newdom;
		},
		showKalert(state, responseObject) {
			state.kalert.text = responseObject.message;
			if (responseObject.text !== "" && responseObject.text !== undefined) state.kalert.text = responseObject.text;
			if (responseObject.alerttype === "" || responseObject.alerttype === "error" || responseObject.alerttype === undefined) responseObject.alerttype = 'danger';
			if (responseObject.success === 1) responseObject.alerttype = 'success';
			state.kalert.type = responseObject.alerttype;
			if (responseObject.type !== "" && responseObject.type !== undefined) state.kalert.type = responseObject.type;
			state.kalert.shown = true;
		},
		closeKalert(state) {
			state.kalert.shown = false;
		},
		triggerFireplaypause(state) {
			state.fireplaypause++;
		},
	},
	actions: {},
});

new Vue({
	router,
	store: store,
	render: h => h(App)
}).$mount('#app')

Vue.mixin({
	methods: {
		numdisplay: function(num) {
			return num.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 0});
		},
		numdisplayNdp: function(num, numberOfDecplc) {
			return num.toLocaleString('en-US', {minimumFractionDigits: numberOfDecplc, maximumFractionDigits: numberOfDecplc});
		},
		ucfirst: function(str) {
			return str[0].toUpperCase() + str.substring(1);
		},
		niceDateTimeFromUnix(unix_timestamp, includeTime) {
			var a = new Date(unix_timestamp * 1000);
			var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
			var year = a.getFullYear();
			var month = months[a.getMonth()];
			var date = a.getDate();

			var timestr = "";
			if(includeTime === true) {
				var hour = a.getHours();
				var min = a.getMinutes();
				if(min < 10) min = "0"+min;
				// var sec = a.getSeconds();
				timestr = ' ' + hour + ':' + min; // + ':' + sec;
			}
			return date + ' ' + month + ' ' + year + timestr;
		},
		ntobr(str) {
			return str.replaceAll("\n","<br/>");
		},
		areArraysIdentical(arrA, arrB) {
			if (arrA === arrB) return true;
			if (arrA == null || arrB == null) return false;
			if (arrA.length !== arrB.length) return false;
			arrA.sort();
			arrB.sort();
			// If you don't care about the order of the elements inside the array, you should sort both arrays here.
			// Please note that calling sort on an array will modify that array. You might want to clone your array first.
			for (var i = 0; i < arrA.length; ++i) {
				if (arrA[i] !== arrB[i]) return false;
			}
			return true;
		},
	},
});