<template>
	<div>
		<HeroSectionTitleOnly title="Podcasts"></HeroSectionTitleOnly>

		<AdSection adtype="header" backgroundclass="bglightgrey"></AdSection>

		<ShowTileSection :tileshows="shows1" :hidetopheadings="true" backgroundclass="bglightgrey" addclasses="nopadtop nopadbot"></ShowTileSection>

		<AdSection adtype="body" backgroundclass="bglightgrey"></AdSection>

		<ShowTileSection :tileshows="shows2" :hidetopheadings="true" backgroundclass="bglightgrey"></ShowTileSection>

		<AdSection adtype="midleader" backgroundclass="bglightgrey"></AdSection>

		<ShowTileSection :tileshows="$store.state.vuepage.slotprops.showtiles" backgroundclass="bgblack"></ShowTileSection>

		<FooterSection></FooterSection>
	</div>
</template>

<script>
import AdSection from "@/components/AdSection";
import ShowTileSection from "@/components/ShowTileSection";
import FooterSection from "@/components/FooterSection";
import HeroSectionTitleOnly from "@/components/HeroSectionTitleOnly";
export default {
	name: "AggregatePodcasts",
	components: {
		HeroSectionTitleOnly,
		FooterSection,
		ShowTileSection,
		AdSection,
	},
	props: {

	},
	data: function () {
		return {

		}
	},
	computed: {
		vp() {
			return this.$store.state.vuepage;
		},
		shows1() {
			var ret = [];
			if(this.vp && this.$store.state.loading === false) {
				for(var i=0; i<4; i++) {
					if(this.vp.slotprops.podcasttiles[i]) {
						ret.push(this.vp.slotprops.podcasttiles[i]);
					}
				}
			}
			return ret;
		},
		shows2() {
			var ret = [];
			if(this.vp && this.$store.state.loading === false) {
				for(var i=4; i<8; i++) {
					if(this.vp.slotprops.podcasttiles[i]) {
						ret.push(this.vp.slotprops.podcasttiles[i]);
					}
				}
			}
			return ret;
		},
		shows3() {
			var ret = [];
			if(this.vp && this.$store.state.loading === false) {
				for(var i=8; i<20; i++) {
					if(this.vp.slotprops.podcasttiles[i]) {
						ret.push(this.vp.slotprops.podcasttiles[i]);
					}
				}
			}
			return ret;
		},
	},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>

</style>