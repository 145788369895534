<template>
	<div class="show-tile-section" :class="addclassStr">
		<div class="container">
			<div v-if="hidetopheadings === false" class="top-items">
				<p class="minor-heading">Our Shows</p>
				<router-link to="/shows/">
					<span class="view-more-textlink text-accent">View Shows</span>
				</router-link>
			</div>
			<div v-if="$store.state.loading === true" class="tile-holder">
				<TileShow v-for="index in [1,2,3,4]" :key="index"></TileShow>
			</div>
			<div v-if="$store.state.loading === false" class="tile-holder">
				<TileShow v-for="(st, index) in tileshows" :showitem="st" :key="index"></TileShow>
			</div>
		</div>
	</div>
</template>

<script>
import TileShow from "@/components/TileShow";
export default {
	name: "ShowTileSection",
	components: {TileShow},
	props: {
		backgroundclass: String,
		tileshows: Array,
		hidetopheadings: Boolean,
		addclasses: String,
	},
	data: function () {
		return {}
	},
	computed: {
		addclassStr() {
			let str = "";
			if(this.backgroundclass) {
				str += this.backgroundclass+" ";
			}
			if(this.addclasses) {
				str += this.addclasses+" ";
			}
			return str;
		}
	},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.show-tile-section {
	padding-top: 60px;
	padding-bottom: 60px;
	display: flex;
}
.top-items {
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
}
.tile-holder {
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
}
.minor-heading {
	color: #FFF;
	padding-right: 20px;
}
.bglightgrey .minor-heading {
	color: #111111;
}
.nopadbot {
	padding-bottom: 0;
}
.nopadtop {
	padding-top: 0;
}
.extrapadbot {
	padding-bottom: 80px;
}
.extrapadtop {
	padding-top: 80px;
}
</style>