<template>
	<div v-if="eventpage" class="event-info-section">
		<div class="top-row">
			<div v-if="eventpage.event_start_date && eventpage.event_start_date.length > 0" class="bglightgrey third">
				<p class="minor-heading">Date</p>
				<div class="info-text">
					<div v-if="eventpage.event_recur_value && eventpage.event_recur_value.length > 0">
						<span class="dateline">{{eventpage.event_recur_value}}</span>
					</div>
					<div v-else>
						<span class="dateline" v-if="eventpage.event_start_date && eventpage.event_start_date.length > 0">{{eventpage.event_start_date}}</span>
						<span class="dateline" v-if="eventpage.event_start_date && eventpage.event_start_date.length > 0 && eventpage.expiration_date && eventpage.expiration_date.length > 0"> to </span>
						<span class="dateline" v-if="eventpage.expiration_date && eventpage.expiration_date.length > 0">{{eventpage.expiration_date}}</span>
					</div>
				</div>
			</div>
			<div v-if="eventpage.event_time && eventpage.event_time.length > 0" class="bglightgrey third">
				<p class="minor-heading">Time</p>
				<p class="info-text">{{eventpage.event_time}}</p>
			</div>
			<div v-if="eventpage.location_town && eventpage.location_town.length > 0" class="bglightgrey third">
				<p class="minor-heading">Town</p>
				<p class="info-text">{{eventpage.location_town}}</p>
			</div>
		</div>
		<div v-if="eventpage.location_address && eventpage.location_address.length > 0" class="bottom-row bglightgrey">
			<p class="minor-heading">Venue</p>
			<p class="info-text">{{eventpage.location_address}}</p>
		</div>
	</div>
</template>

<script>
export default {
	name: "EventInfoSection",
	components: {},
	props: {
		eventpage: Object,
	},
	data: function () {
		return {}
	},
	computed: {},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.event-info-section {
	max-width: 960px;
	margin: 20px auto 0;
}
.top-row {
	width: 100%;
	margin-bottom: 10px;
	display: flex;
	justify-content: space-between;
	color: #111111;
}
.third {
	width: 32.5%;
	padding: 20px 30px;
}
.bottom-row {
	width: 100%;
	padding: 20px;
	color: #111111;
}
.minor-heading {
	font-family: 'Montserrat';
	color: #111111;
	font-size: 14px;
	font-weight: normal;
	text-transform: uppercase;
	margin-bottom: 5px;
}
.info-text {
	font-family: 'Montserrat';
	font-size: 20px;
	font-weight: 600;
	color: #111111;
}
.dateline {
	display: block;
	color: #111111;
}
@media screen and (max-width: 900px) {
	.minor-heading {
		font-size: 13px;
	}
	.info-text {
		font-size: 16px;
	}
}
@media screen and (max-width: 500px) {
	.top-row {
		flex-wrap: wrap;
	}
	.third {
		width: 100%;
	}
}
</style>