<template>
	<div class="dir-item">
		<div class="left-half" :class="{smallertile : vp.slotprops.use_smaller_tiles === 1}">
			<img :src="item.hero_image" :alt="item.heading" />
		</div>
		<div class="right-half">
			<div class="textinfo">
				<p class="dir-item-title">{{item.heading}}</p>
				<div class="dir-content" v-html="item.article_content"></div>
				<a class="solid-button" v-if="item.hero_url1.length > 0" :href="item.hero_url1" target="_blank">Visit Website</a>
				<a class="solid-button" v-if="item.hero_url2.length > 0" :href="item.hero_url2" target="_blank">Order Online</a>
				<div class="social-links">
					<a v-if="item.facebook_link.length > 0" :href="item.facebook_link" target="_blank" title="Facebook">
						<i class="mdi mdi-facebook" style="font-size: 20px;"></i>
					</a>
					<a v-if="item.instagram_link.length > 0" :href="item.instagram_link" target="_blank" title="Instagram">
						<i class="mdi mdi-instagram" style="font-size: 20px;"></i>
					</a>
					<a v-if="item.twitter_link.length > 0" :href="item.twitter_link" target="_blank" title="Twitter/X">
						<img class="svg-icon" src="/public-static/x-twitter.svg" />
					</a>
					<a v-if="item.tiktok_link.length > 0" :href="item.tiktok_link" target="_blank" title="TikTok">
						<img class="svg-icon" src="/public-static/tiktok.svg" />
					</a>
					<a v-if="item.linkedin_link.length > 0" :href="item.linkedin_link" target="_blank" title="LinkedIn">
						<i class="mdi mdi-linkedin" style="font-size: 20px;"></i>
					</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "DirItem",
	components: {},
	props: {
		item: Object,
	},
	data: function () {
		return {}
	},
	computed: {
		vp() {
			return this.$store.state.vuepage;
		}
	},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>
<style>
.article-inside .dir-content p {
	line-height: 1.4;
	font-size: 13px;
	margin-bottom: 0;
}
</style>
<style scoped>
.dir-item {
	background-color: #FFF;
	overflow: hidden;
	margin-bottom: 40px;
	position: relative;
	display: flex;
	box-shadow: 0 0 15px 10px rgb(0 0 0 / 10%);
}
.left-half {
	width: 50%;
	height: 600px;
}
.left-half.smallertile {
	height: 435px;
}
.left-half img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.right-half {
	width: 50%;
	display: flex;
	align-items: center;
	text-align: center;
	justify-content: center;
	padding-bottom: 20px;
}
.textinfo {
	width: 90%;
}
.dir-item-title {
	font-size: 22px;
	-webkit-margin-before: 0;
	font-family: 'Montserrat';
	margin-top: 20px;
}
.social-links {
	margin-top: 20px;
}
.solid-button {
	margin-top: 20px;
	padding: 12px 35px;
	border-radius: 35px;
	color: #fff;
	font-weight: 600;
	letter-spacing: 0.05em;
}
.svg-icon {
	height: 15px;
	margin-top: -5px;
	color: #000;
}

@media screen and (max-width: 800px) {
	.dir-item {
		flex-wrap: wrap;
	}
	.left-half, .right-half {
		width: 100%;
	}
	.left-half {
		height: 300px;
	}
}
</style>