<template>
	<div>
		<HeroSectionTitleOnly title="Search Results"></HeroSectionTitleOnly>

		<AdSection adtype="header" backgroundclass="bglightgrey"></AdSection>

		<div class="search-section bglightgrey">
			<div class="container">
				<b-input-group class="search-box-container">
					<b-form-input v-model="searchText" @keypress.enter="dosearch" placeholder="Search"></b-form-input>
					<b-input-group-append>
						<b-button @click="dosearch">
							<b-icon icon='search' class="text-white"></b-icon>
						</b-button>
					</b-input-group-append>
				</b-input-group>
			</div>
		</div>

		<div class="search-section-info bglightgrey" v-if="isQuerying">
			<div class="container">
				<p>Searching...please wait</p>
			</div>
		</div>

		<div class="search-section-info bglightgrey" v-if="isQuerying === false && items && items.length === 0">
			<div class="container">
				<p>No search results found</p>
			</div>
		</div>

		<ArticleTileSection v-if="!isQuerying" :tilenewsitems="items"></ArticleTileSection>

		<FooterSection></FooterSection>
	</div>
</template>

<script>
import AdSection from "@/components/AdSection";
import ArticleTileSection from "@/components/ArticleTileSection";
import FooterSection from "@/components/FooterSection";
import axios from "axios";
import HeroSectionTitleOnly from "@/components/HeroSectionTitleOnly";
export default {
	name: "SearchResults",
	components: {
		HeroSectionTitleOnly,
		FooterSection,
		ArticleTileSection,
		AdSection,
	},
	props: {

	},
	data: function () {
		return {
			searchText: '',
			isQuerying: true,
			items: [],
		}
	},
	computed: {
		vp() {
			return this.$store.state.vuepage;
		},
		stextStore() {
			return this.$store.state.searchVal;
		},
	},
	methods: {
		dosearch() {
			if(this.searchText.length === 0) {
				this.isQuerying = false;
				return false;
			}
			this.isQuerying = true;
			var form = {};
			form.searchtext = this.searchText;
			form.domain = this.$store.state.domain;
			var self = this;
			axios.post(
				"/post/search.php",
				JSON.stringify({data: form})
			).then(function(response) {
				self.items = response.data.results;
				self.isQuerying = false;
			}).catch(function(error) {
				console.log(error);
			});
		},
	},
	watch: {
		stextStore() {
			this.searchText = this.$store.state.searchVal;
			setTimeout(function() {
				this.dosearch();
			}.bind(this), 100);
		},
	},
	mounted() {
		let urlparams = this.$route.query; //query for GET vars, params for /:id type vue params
		if(urlparams.q) {
			this.searchText = urlparams.q;
			this.dosearch();
		}
		else {
			this.isQuerying = false;
		}
	}
}
</script>

<style scoped>
.search-section {
	padding: 0 0 20px;
}
.search-box-container {
	margin: 0 auto;
	max-width: 600px;
}
.search-section-info {
	padding: 40px 0 100px;
}
</style>