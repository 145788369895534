<template>
	<div>
		<HeroSection :page="$store.state.vuepage.slotprops.heropage" :showlinkbutton="true"></HeroSection>

		<ListnrSection></ListnrSection>

		<AdSection v-if="!$store.state.metroDomains.includes($store.state.domain)" adtype="header" backgroundclass="bggrey"></AdSection>
		<RecentlyPlayedSection v-if="$store.state.metroDomains.includes($store.state.domain)"></RecentlyPlayedSection>

		<ShowTileSection :tileshows="$store.state.vuepage.slotprops.showtiles" backgroundclass="bgblack"></ShowTileSection>

		<LiveMPSection v-if="$store.state.domain === '3mp'" :page="$store.state.vuepage.slotprops" :showlinkbutton="true"></LiveMPSection>

		<PodcastTileSection v-if="$store.state.vuepage.slotprops.podcasttiles && $store.state.vuepage.slotprops.podcasttiles.length > 0" :tileshows="$store.state.vuepage.slotprops.podcasttiles"></PodcastTileSection>
		<AdSection adtype="body" backgroundclass="bglightgrey"></AdSection>
		<ArticleTileSection :tilenewsitems="$store.state.vuepage.slotprops.newstiles"></ArticleTileSection>
		<AdSection adtype="midleader"></AdSection>
		<FooterSection></FooterSection>
	</div>
</template>

<script>
import HeroSection from "@/components/HeroSection";
import AdSection from "@/components/AdSection";
import ShowTileSection from "@/components/ShowTileSection";
import PodcastTileSection from "@/components/PodcastTileSection";
import ArticleTileSection from "@/components/ArticleTileSection";
import FooterSection from "@/components/FooterSection";
import RecentlyPlayedSection from "@/components/RecentlyPlayedSection";
import LiveMPSection from "@/components/LiveMPSection.vue";
import ListnrSection from "@/components/ListnrSection.vue";
export default {
	name: "Home",
	components: {
		ListnrSection,
		LiveMPSection,
		RecentlyPlayedSection,
		FooterSection,
		ArticleTileSection,
		PodcastTileSection,
		ShowTileSection,
		AdSection,
		HeroSection
	},
	props: {

	},
	data: function () {
		return {

		}
	},
	computed: {
		vp() {
			return this.$store.state.vuepage;
		}
	},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>

</style>