<template>
	<div class="">

		<Home v-if="pagetemplate === 'home'"></Home>

		<ArticleShow v-if="pagetemplate === 'article-shows'"></ArticleShow>

		<AggregateShows v-if="pagetemplate === 'aggregate-shows'"></AggregateShows>

		<ArticleNews v-if="pagetemplate === 'article-news'"></ArticleNews>

		<AggregateNews v-if="pagetemplate === 'aggregate-news'"></AggregateNews>

		<ArticleCompetition v-if="pagetemplate === 'article-competition'"></ArticleCompetition>

		<AggregateComps v-if="pagetemplate === 'aggregate-comps'"></AggregateComps>

		<ArticlePodcasts v-if="pagetemplate === 'article-podcasts'"></ArticlePodcasts>

		<AggregatePodcasts v-if="pagetemplate === 'aggregate-podcasts'"></AggregatePodcasts>

		<ArticleEvent v-if="pagetemplate === 'article-events'"></ArticleEvent>

		<AggregateEvents v-if="pagetemplate === 'aggregate-events'"></AggregateEvents>

		<PublicForm v-if="pagetemplate === 'public-form'"></PublicForm>

		<SimplePage v-if="pagetemplate === 'simple-page'"></SimplePage>

		<PDFHolder v-if="pagetemplate === 'pdf_holder'"></PDFHolder>

		<Directory v-if="pagetemplate === 'directory'"></Directory>

		<SearchResults v-if="pagetemplate === 'search'"></SearchResults>

<!--		<ErrorPage v-if="pagetemplate === 'error'"></ErrorPage>-->

<!--		<BasicPage v-if="pagetemplate === 'basic-page'"></BasicPage>-->

	</div>
</template>

<script>
import Home from "@/components/pageviews/Home";
import ArticleShow from "@/components/pageviews/ArticleShow";
import SimplePage from "@/components/pageviews/SimplePage";
import ArticleNews from "@/components/pageviews/ArticleNews";
import AggregateNews from "@/components/pageviews/AggregateNews";
import AggregateShows from "@/components/pageviews/AggregateShows";
import AggregateComps from "@/components/pageviews/AggregateComps";
import ArticleCompetition from "@/components/pageviews/ArticleCompetition";
import ArticleEvent from "@/components/pageviews/ArticleEvent";
import AggregateEvents from "@/components/pageviews/AggregateEvents";
import PublicForm from "@/components/pageviews/PublicForm";
import PDFHolder from "@/components/pageviews/PDFHolder";
import Directory from "@/components/pageviews/Directory";
import SearchResults from "@/components/pageviews/SearchResults";
import ArticlePodcasts from "@/components/pageviews/ArticlePodcasts";
import AggregatePodcasts from "@/components/pageviews/AggregatePodcasts";
// import ErrorPage from "@/components/pageviews/ErrorPage";
// import BasicPage from "@/components/pageviews/BasicPage";

export default {
	name: 'RouterManager',
	components: {
		AggregatePodcasts,
		ArticlePodcasts,
		SearchResults,
		Directory,
		PDFHolder,
		PublicForm,
		AggregateEvents,
		ArticleEvent,
		ArticleCompetition,
		AggregateComps,
		AggregateShows,
		AggregateNews,
		ArticleNews,
		SimplePage,
		ArticleShow,
		// BasicPage,
		// ErrorPage,
		Home,
	},
	data() {
		return {

		}
	},
	computed: {
		pagetemplate() {
			return this.$store.state.pagetemplate;
		},
	},
	props: [],
	methods: {

	}
}

</script>
