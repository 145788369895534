<template>
	<div>
		<HeroSectionForm :page="$store.state.vuepage.slotprops" :showlinkbutton="false"></HeroSectionForm>

		<ContactPageContent :page="vp"></ContactPageContent>

		<AdSection adtype="header" backgroundclass="bggrey"></AdSection>

		<ContactPageMain :page="vp"></ContactPageMain>

		<SubmitEventForm v-if="vp.name === 'submit-event'" :page="vp"></SubmitEventForm>

		<ContactForm v-else :page="vp"></ContactForm>

		<AdSection adtype="body" backgroundclass="bglightgrey"></AdSection>

		<ShowTileSection :tileshows="$store.state.vuepage.slotprops.showtiles" backgroundclass="bglightgrey" addclasses="nopadtop"></ShowTileSection>

		<AdSection adtype="midleader" backgroundclass="bglightgrey"></AdSection>

		<FooterSection></FooterSection>
	</div>
</template>

<script>
import AdSection from "@/components/AdSection";
import ShowTileSection from "@/components/ShowTileSection";
import FooterSection from "@/components/FooterSection";
import HeroSectionForm from "@/components/HeroSectionForm";
import ContactPageContent from "@/components/ContactPageContent";
import ContactPageMain from "@/components/ContactPageMain";
import ContactForm from "@/components/forms/ContactForm";
import SubmitEventForm from "@/components/forms/SubmitEventForm";
export default {
	name: "PublicForm",
	components: {
		SubmitEventForm,
		ContactForm,
		ContactPageMain,
		ContactPageContent,
		HeroSectionForm,
		FooterSection,
		ShowTileSection,
		AdSection,
	},
	props: {

	},
	data: function () {
		return {

		}
	},
	computed: {
		vp() {
			return this.$store.state.vuepage;
		}
	},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>

</style>