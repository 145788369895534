<template>
	<div>
		<HeroSectionForm :page="$store.state.vuepage.slotprops" :showlinkbutton="false"> </HeroSectionForm>

		<div v-if="$store.state.loading === false" class="article-section">
			<div class="container">
				<div class="article-inside">
					<div v-if="vp.slotprops.pdf_file_url && vp.slotprops.pdf_file_url.length > 0">
						<a :href="vp.slotprops.pdf_file_url" target="_blank" style="display: flex; align-items: center;">
							<i class="mdi mdi-file-pdf" style="font-size: 28px;"></i>
							<span style="margin-left: 10px;">Download PDF Version</span>
						</a>
					</div>

					<div class="select-holders">
						<select v-model="chosenTownId" class="dirselect">
							<option v-for="opt in townOptions" :key="opt.id" :value="opt.id">{{opt.name}}</option>
						</select>

						<select v-model="chosenCat" class="dirselect">
							<option v-for="opt in catOptions" :key="opt.id" :value="opt.id">{{opt.name}}</option>
						</select>
					</div>

					<DirCat v-for="dircat in filteredCats" :catitem="dircat" :chosentownid="chosenTownId" :key="dircat.id"></DirCat>

				</div>
			</div>
		</div>

		<FooterSection></FooterSection>
	</div>
</template>

<script>
import FooterSection from "@/components/FooterSection";
import HeroSectionForm from "@/components/HeroSectionForm";
import DirCat from "@/components/dirs/DirCat";

export default {
	name: "Directory",
	components: {
		DirCat,
		HeroSectionForm,
		FooterSection,
	},
	props: {

	},
	data: function () {
		return {
			chosenCat: 0,
			chosenTownId: 0,
		}
	},
	computed: {
		catOptions() {
			var c = [];
			let cat_first_title = 'All Categories';
			if(this.vp.slotprops.styles_not_cat) cat_first_title = 'All Styles';
			c.push({id: 0, name: cat_first_title});
			if(this.dircats) {
				for (var cind = 0; cind < this.dircats.length; cind++) {
					var myc = this.dircats[cind];
					if (myc.items.length > 0) {
						c.push({id: myc.id, name: myc.title});
					}
				}
			}
			return c;
		},
		filteredCats() {
			if(this.chosenCat === 0) {
				return this.dircats;
			}
			return this.dircats.filter(item => item.id === this.chosenCat);
		},
		vp() {
			return this.$store.state.vuepage;
		},
		dircats() {
			return this.vp.slotprops.categories;
		},
		townOptions() {
			let c = [];
			if(this.vp.pageid === 54070) c.push({id: 0, name: 'All Locations'}); // 3MP Peninsula Picks
			else c.push({id: 0, name: 'All Regions'});
			if(this.dirtowns) {
				for (var cind = 0; cind < this.dirtowns.length; cind++) {
					var myc = this.dirtowns[cind];
					c.push({id: myc.id, name: myc.name});
				}
			}
			return c;
		},
		dirtowns() {
			return this.vp.slotprops.townslist;
		},
	},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.article-inside {
	width: 1000px;
	min-height: 500px;
	padding: 50px 50px;
	margin: 0 auto;
}
.select-holders {
	padding: 30px 0;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}
.dirselect {
	background: #F5F5F5;
	padding: 15px;
	border: 1px solid #CCC;
	border-radius: 10px;
	font-size: 14px;
	margin-bottom: 20px;
}

@media screen and (max-width: 1100px) {
	.article-inside {
		width: 100%;
		padding-left: 0;
		padding-right: 0;
	}
}
</style>