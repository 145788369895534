<template>
	<div class="listnr-section">
		<div class="container">
			<a class="listnr-inside" :href="listnrLink" target="_blank">
				<p class="ptext">Listen Now On</p>
				<img class="listnr-logo" src="/public-static/logos/listnr-mint.png" alt="Listnr logo" />
			</a>
		</div>
	</div>
</template>

<script>

export default {
	name: "ListnrSection",
	components: {},
	props: {},
	data: function () {
		return {
			fetching: false,
			nowplaying: {},
			recents: [],
		}
	},
	computed: {
		globals() {
			return this.$store.state.globals;
		},
		dom() {
			return this.$store.state.domain
		},
		listnrLink() {
			return this.globals.domainInfo.listnrLink;
		},
		streamingLink() {
			return this.globals.domainInfo.streamingLink;
		},
	},
	methods: {

	},
	watch: {},
	mounted() {

	},
	beforeDestroy() {

	},
}
</script>

<style scoped>
.listnr-section {
	background-color: #000;
	background-image: url('/public-static/backgrounds/listnr_bg_wide.png');
	background-size: cover;
	padding-top: 50px;
	padding-bottom: 50px;
	display: flex;
	position: relative;
	justify-content: center;
	align-items: center;
}
.listnr-inside {
	display: flex;
	width: 100%;
	padding: 0 ;
	position: relative;
	justify-content: center;
	align-items: center;
	gap: 30px;
}
.ptext {
	color: #fff;
	font-size: 35px;
	transition: 0.3s all ease-in-out;
}
.listnr-inside:hover .ptext {
	color: #81E4BD;
}
.listnr-logo {
	width: 240px;
}
@media screen and (max-width: 640px) {
	.listnr-inside {
		flex-direction: column;
	}
}
</style>