<template>
	<div>
		<HeroSectionTitleOnly :title="$store.state.vuepage.slotprops.heading" :showlinkbutton="false"></HeroSectionTitleOnly>

		<ArticleSection :content="$store.state.vuepage.slotprops.article_content" :scriptinfo="$store.state.vuepage.slotprops.article_content_script" addclasses="extrapadbot extrapadtop"></ArticleSection>

		<FooterSection></FooterSection>
	</div>
</template>

<script>
import FooterSection from "@/components/FooterSection";
import ArticleSection from "@/components/ArticleSection";
import HeroSectionTitleOnly from "@/components/HeroSectionTitleOnly";

export default {
	name: "SimplePage",
	components: {
		HeroSectionTitleOnly,
		ArticleSection,
		FooterSection,
	},
	props: {

	},
	data: function () {
		return {

		}
	},
	computed: {
		vp() {
			return this.$store.state.vuepage;
		}
	},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>

</style>