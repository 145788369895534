<template>
	<div>
		<HeroSection :page="$store.state.vuepage.slotprops" :showlinkbutton="false"></HeroSection>

		<AdSection adtype="header" backgroundclass="bggrey"></AdSection>

		<ArticleSection :content="$store.state.vuepage.slotprops.article_content" :scriptinfo="$store.state.vuepage.slotprops.article_content_script"></ArticleSection>

		<AdSection adtype="body" backgroundclass="bgwhite"></AdSection>

		<ArticleSection :content="$store.state.vuepage.slotprops.article_content_sec" :scriptinfo="$store.state.vuepage.slotprops.article_content_sec_script"></ArticleSection>

		<ShowTileSection :tileshows="$store.state.vuepage.slotprops.showtiles" backgroundclass="bggrey"></ShowTileSection>

		<AdSection adtype="midleader" backgroundclass="bggrey"></AdSection>

		<FooterSection></FooterSection>
	</div>
</template>

<script>
import HeroSection from "@/components/HeroSection";
import AdSection from "@/components/AdSection";
import ShowTileSection from "@/components/ShowTileSection";
import FooterSection from "@/components/FooterSection";
import ArticleSection from "@/components/ArticleSection";
export default {
	name: "ArticlePodcasts",
	components: {
		ArticleSection,
		FooterSection,
		ShowTileSection,
		AdSection,
		HeroSection
	},
	props: {

	},
	data: function () {
		return {

		}
	},
	computed: {
		vp() {
			return this.$store.state.vuepage;
		}
	},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>

</style>