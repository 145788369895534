<template>
	<div v-if="$store.state.loading === false" class="article-section bggrey">
		<div class="container">
			<div v-if="page.name === 'contact-us'" class="article-inside text-white">
				<p class="article-subheading">Contact Us</p>
				<div class="column-holder">
					<div class="colwc">
						<div v-html="page.slotprops.entry_form_embed"></div>
					</div>
					<div class="colwc">
						<div v-html="page.slotprops.article_content"></div>
						<div>
							<a class="link-text-in-page" href="/forms/advertising-enquiry" data-targpagetpl="public-form">Advertise With Us</a>
							<a v-if="$store.state.domain !== '3mp'" class="link-text-in-page" href="/forms/report-news" data-targpagetpl="public-form">Send A News Tip</a>
							<a class="link-text-in-page" href="/forms/submit-event" data-targpagetpl="public-form">Add An Event</a>
							<a v-if="$store.state.metroDomains.indexOf($store.state.domain) === -1" class="link-text-in-page" target="_blank" :href="page.slotprops.local_content_grid_url">Local Content Grid</a>
							<a v-if="$store.state.metroDomains.indexOf($store.state.domain) === -1" class="link-text-in-page" target="_blank" href="/pdf-holders/summer-2022-acma-local-content-plan/">ACMA Local Content Plan</a>
						</div>
					</div>
				</div>
			</div>
			<div v-else class="article-inside text-white">
				<div v-html="page.slotprops.article_content"></div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "ContactPageContent",
	components: {},
	props: {
		page: Object,
	},
	data: function () {
		return {}
	},
	computed: {},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>
.article-inside {
	width: 1000px;
	padding: 80px 50px 1px;
	margin: 0 auto;
}
.article-subheading {
	font-size: 30px;
}
.column-holder {
	display: flex;
	justify-content: space-between;
}
.colwc {
	width: 48%;
}
.link-text-in-page {
	display: block;
	text-transform: uppercase;
	font-size: 13px;
	margin-bottom: 20px;
	transition: all 0.2s ease-in-out;
}
.fm .link-text-in-page {
	color: #EA214F;
}
.am .link-text-in-page {
	color: #1980E2;
}
.mp .link-text-in-page {
	color: #25BEC8;
}
.magic .link-text-in-page {
	color: #F06312;
}
.link-text-in-page:hover {
	color: #FFF;
}
@media screen and (max-width: 1100px) {
	.article-inside {
		width: 100%;
		padding-left: 0;
		padding-right: 0;
	}
}
@media screen and (max-width: 840px) {
	.column-holder {
		flex-wrap: wrap;
	}
	.colwc {
		width: 100%;
	}
}
</style>