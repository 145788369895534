<template>
	<div class="container">
		<div class="form-holder">
			<b-form @submit="submitForm">
				<div class="form-item-hold">
					<b-form-group id="input-group-1" :state="nameState" :invalid-feedback="nameInvalidFeedback">
						<b-form-input v-model="formdata.title" placeholder="Title/Heading of event" :state="nameState"></b-form-input>
					</b-form-group>
				</div>

				<div class="form-item-hold">
					<b-form-group>
						<b-form-input v-model="formdata.subheading" placeholder="Subheading (optional)"></b-form-input>
					</b-form-group>
				</div>

				<div class="form-item-hold">
					<b-form-datepicker
							v-model="formdata.event_start_date"
							locale="en-US"
							placeholder="Event Start Date"
							autocomplete="off"
							:state="startDateState" :invalid-feedback="dateInvalidFeedback"
					></b-form-datepicker>
				</div>

				<div class="form-item-hold">
					<b-form-datepicker
							v-model="formdata.expiration_date"
							locale="en-US"
							placeholder="Event End Date"
							autocomplete="off"
							:state="endDateState" :invalid-feedback="dateInvalidFeedback"
					></b-form-datepicker>
				</div>

				<div class="form-item-hold">
					<b-form-group id="input-group-5" :state="timeState" :invalid-feedback="timeInvalidFeedback">
						<b-form-input v-model="formdata.event_time" placeholder="Start & End time of event" :state="timeState"></b-form-input>
					</b-form-group>
				</div>

				<div class="form-item-hold">
					<b-form-group id="input-group-6" :state="locTownState" :invalid-feedback="nameInvalidFeedback">
						<b-form-input v-model="formdata.location_town" placeholder="Event Location Address" :state="locTownState"></b-form-input>
					</b-form-group>
				</div>

				<div class="form-item-hold">
					<b-form-group id="input-group-7" label-for="input-7" :state="locAddrState" :invalid-feedback="msgInvalidFeedback">
						<b-form-textarea
								id="event_details"
								v-model="formdata.location_address"
								placeholder="Event Location Address"
								rows="6" max-rows="6"
						></b-form-textarea>
					</b-form-group>
				</div>

				<div class="form-item-hold">
					<b-form-group id="input-group-4" label-for="input-4" :state="msgState" :invalid-feedback="msgInvalidFeedback">
						<b-form-textarea
								id="event_details"
								v-model="formdata.event_details"
								placeholder="Event Details (nb. if this is a recurring event please specify)"
								rows="6" max-rows="6"
						></b-form-textarea>
					</b-form-group>
				</div>

				<div class="form-item-hold">
					<b-form-group>
						<b-form-input v-model="formdata.event_link" placeholder="Event Website (if there is one)"></b-form-input>
					</b-form-group>
				</div>

				<div class="form-item-hold">
					<b-form-file accept="image/*"
						v-model="imageuploadfile"
						placeholder="Image for use on event page listing (jpg, png, gif)"
						drop-placeholder="Drop files here"
					></b-form-file>
<!--					<div style="font-size: 12px;">Selected file: {{ imageuploadfile ? imageuploadfile.name : '' }}</div>-->
				</div>

				<div class="form-item-hold">
					<b-form-group id="input-group-11" :state="contactNameState" :invalid-feedback="nameInvalidFeedback">
						<b-form-input v-model="formdata.event_contact_name" placeholder="Contact Name (contact details will not be published)" :state="nameState"></b-form-input>
					</b-form-group>
				</div>

				<div class="form-item-hold">
					<b-form-group id="input-group-3" :state="phoneState" :invalid-feedback="phoneInvalidFeedback">
						<b-form-input v-model="formdata.event_contact_phone" placeholder="Contact Phone" :state="phoneState"></b-form-input>
					</b-form-group>
				</div>

				<div class="form-item-hold">
					<b-form-group id="input-group-2" :state="emailState" :invalid-feedback="emailInvalidFeedback">
						<b-form-input v-model="formdata.event_contact_email" placeholder="Contact Email Address" :state="emailState"></b-form-input>
					</b-form-group>
				</div>

				<input type="hidden" id="robots" ref="robots" v-model="robots" />
			</b-form>

			<div v-if="submissionComplete === false" class="primary-button bgdark textwhite" v-on:click="submitForm" v-bind:class="{'button-is-working':(formworking === true)}">
				<span>Submit</span>
				<WorkingButtonInsert v-if="formworking === true"></WorkingButtonInsert>
			</div>

		</div>
	</div>
</template>

<script>
import axios from "axios";
import WorkingButtonInsert from "@/components/WorkingButtonInsert";
export default {
	name: "SubmitEventForm",
	components: {
		WorkingButtonInsert
	},
	props: {
		page: Object,
	},
	data: function () {
		return {
			checksTriggered: false,
			formworking: false,
			submissionComplete: false,
			formdata: {
				title: '',
				subheading: '',
				event_start_date: '',
				expiration_date: '',
				event_time: '',
				location_town: '',
				location_address: '',
				event_details: '',
				event_link: '',
				event_image: '',
				event_contact_name: '',
				event_contact_phone: '',
				event_contact_email: '',
			},
			imageuploadfile: null,
			robots: '',
		}
	},
	computed: {
		formName() {
			return this.page.name;
		},
		nameState() {
			if(this.checksTriggered === false && this.formdata.title.length < 2) {
				return null;
			}
			else if(this.formdata.title.length >= 2) {
				return true;
			}
			return false;
		},
		nameInvalidFeedback() {
			return 'Please enter something.'
		},
		timeState() {
			if(this.checksTriggered === false && this.formdata.event_time.length < 2) {
				return null;
			}
			else if(this.formdata.event_time.length >= 2) {
				return true;
			}
			return false;
		},
		timeInvalidFeedback() {
			return "Please enter a valid time";
		},
		startDateState() {
			if(this.checksTriggered === false) {
				return null;
			}
			else if(this.formdata.event_start_date.length >= 10) {
				return true;
			}
			return false;
		},
		endDateState() {
			if(this.checksTriggered === false) {
				return null;
			}
			else if(this.formdata.expiration_date.length >= 10) {
				return true;
			}
			return false;
		},
		dateInvalidFeedback() {
			return "Please select a date";
		},
		locTownState() {
			if(this.checksTriggered === false) {
				return null;
			}
			else if(this.formdata.location_town.length >= 3) {
				return true;
			}
			return false;
		},
		locAddrState() {
			if(this.checksTriggered === false) {
				return null;
			}
			else if(this.formdata.location_address.length >= 3) {
				return true;
			}
			return false;
		},
		contactNameState() {
			if(this.checksTriggered === false) {
				return null;
			}
			else if(this.formdata.event_contact_name.length >= 3) {
				return true;
			}
			return false;
		},
		emailState() {
			if(this.checksTriggered === false && this.formdata.event_contact_email.length >= 0 && /.+@.+\..+/.test(this.formdata.event_contact_email) === false) {
				return null;
			}
			else if(this.formdata.event_contact_email.length >= 0 && /.+@.+\..+/.test(this.formdata.event_contact_email)) {
				return true;
			}
			return false;
		},
		emailInvalidFeedback() {
			return 'Please enter a valid email address.'
		},
		phoneState() {
			if(this.checksTriggered === false && this.formdata.event_contact_phone.length < 6) {
				return null;
			}
			else if(this.formdata.event_contact_phone.length >= 6) {
				return true;
			}
			return false;
		},
		phoneInvalidFeedback() {
			return 'Please enter a valid phone number.'
		},
		msgState() {
			if(this.checksTriggered === false && this.formdata.event_details.length === 0) {
				return null;
			}
			else if(this.formdata.event_details.length > 1000 || this.formdata.event_details.length === 0) {
				return false;
			}
			return true;
		},
		msgInvalidFeedback() {
			return 'Please enter a message. Max 1000 characters.';
		},
	},
	methods: {
		submitForm() {
			if(this.formworking === false) { //only proceed if not already waiting on callback
				this.checksTriggered = true; //check for validations
				var checkitems = [
					this.nameState, this.timeState, this.startDateState, this.endDateState,
					this.locTownState, this.locAddrState, this.contactNameState, this.emailState,
					this.phoneState, this.msgState
				];
				for(var i=0; i<checkitems.length; i++) {
					if(checkitems[i] === false) return false;
				}
				if(this.robots.length > 0) return false;
				this.formworking = true; //make button working class
				var form = new FormData();
				form.append('formdata', JSON.stringify(this.formdata));
				form.append('domain', this.$store.state.domain);
				form.append('formname', this.formName);
				if(this.imageuploadfile !== null) {
					form.append('file-upload', this.imageuploadfile, this.imageuploadfile.name);
				}
				var self = this;
				axios.post("/post/submit-event-form.php", form).then(function(response) {
					var responsejson = response.data; //response.data is returned info
					self.formworking = false;
					if(responsejson.error === 1) {
						self.$store.commit("showKalert", responsejson);
					}
					else if(responsejson.success === 1) {
						self.$store.commit("showKalert", responsejson);
						self.submissionComplete = true;
					}
				}).catch(function(error) {
					console.log(error);
				});
			}
		},
	},
	watch: {},
	mounted() {

	}
}
</script>
<style scoped>

</style>