<template>
	<div>
		<HeroSection :page="$store.state.vuepage.slotprops" :showlinkbutton="false"></HeroSection>

		<AdSection adtype="header" backgroundclass="bggrey"></AdSection>

		<ArticleSection :content="$store.state.vuepage.slotprops.article_content" :scriptinfo="$store.state.vuepage.slotprops.article_content_script"></ArticleSection>

		<AdSection adtype="body" backgroundclass="bgwhite"></AdSection>

		<ArticleSection :content="$store.state.vuepage.slotprops.article_content_sec" :scriptinfo="$store.state.vuepage.slotprops.article_content_sec_script" :addclasses="extrapadclass"></ArticleSection>

		<ShowEDMSignup v-if="showEdmComponentIds.includes($store.state.vuepage.pageid)"></ShowEDMSignup>

		<ShowTileSection :tileshows="$store.state.vuepage.slotprops.showtiles" backgroundclass="bggrey"></ShowTileSection>

		<AdSection adtype="midleader" backgroundclass="bggrey"></AdSection>

		<FooterSection></FooterSection>
	</div>
</template>

<script>
import HeroSection from "@/components/HeroSection";
import AdSection from "@/components/AdSection";
import ShowTileSection from "@/components/ShowTileSection";
import FooterSection from "@/components/FooterSection";
import ArticleSection from "@/components/ArticleSection";
import ShowEDMSignup from "@/components/forms/ShowEDMSignup";
export default {
	name: "ArticleShow",
	components: {
		ShowEDMSignup,
		ArticleSection,
		FooterSection,
		ShowTileSection,
		AdSection,
		HeroSection
	},
	props: {

	},
	data: function () {
		return {
			showEdmComponentIds: [
				1173, //morning crew fm
				1179, //morning rush am
			],
		}
	},
	computed: {
		vp() {
			return this.$store.state.vuepage;
		},
		extrapadclass() {
			if(this.showEdmComponentIds.includes(this.$store.state.vuepage.pageid)) {
				return '';
			}
			return 'extrapadbot';
		},
	},
	methods: {},
	watch: {},
	mounted() {

	}
}
</script>

<style scoped>

</style>